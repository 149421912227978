<template>
  <ion-footer class="chat-input-container">
    <ion-item class="chat-input">
      <ion-textarea placeholder="Type a message" v-model="message" @ionFocus.prevent="scrollToBottom"/>
      <ion-button slot="end" @click="sendMessage" shape="round" color="primary" class="chat-send-button">
        <ion-icon :ios="arrowUp" :md="send"/>
      </ion-button>
    </ion-item>
  </ion-footer>
</template>

<script>
import { defineComponent } from 'vue'
import { IonFooter, IonItem, IonTextarea, IonIcon, IonButton } from '@ionic/vue'
import { arrowUp, send } from 'ionicons/icons'

export default defineComponent({
  name: 'MessageInput',
  setup () {return {arrowUp, send}},
  components: {
    IonFooter,
    IonItem,
    IonTextarea,
    IonIcon,
    IonButton,
  },
  emits: [
    'sendMessage',
    'scrollToBottom',
  ],
  data () {
    return {
      message: '',
    }
  },
  methods: {
    sendMessage () {
      if (this.message) {
        this.$emit('sendMessage', this.message)
        this.message = ''
      }
    },
    scrollToBottom () {
      this.$emit('scrollToBottom')
    },
    blurInput () {
      let input = document.getElementsByTagName('ion-textarea')[0]
      if (input) {
        return input.setBlur()
      }
    },
  },
})
</script>

<style scoped>
.chat-input {
  display: flex;
  align-items: center;
  --border-width: 2px;
  --border-radius: 20px;
  --padding-start: 10px;
  margin: 5px 25px 25px 25px;
  --inner-border-width: 0;
  --highlight-color-focused: transparent;
}
.chat-send-button {
  margin: 0;
  height: 2.5em;
  width: 2.5em;
  --border-radius: 2em;
}
.chat-input-container {
  background: var(--ion-background-color);
}
.chat-input-container::before {
  display: none;
}
</style>
