<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href='/profile' @click.stop="handleBackNavigation"/>
        </ion-buttons>
        <ion-title>
          {{chatName}}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" id="ion-content">
      <ion-loading :is-open="loading" message="Loading messages..." :duration="20000"
        cssClass="medium-loading-container"/>
      <ion-toast position="top" color="danger" :is-open="error" :message="errorMessage" :duration="5000"
        @didDismiss="dismissError"/>
      <div v-if="messageList.length" class="message-container">
        <Message v-for="message in messageList" :key="message.id" :message="message" @scrollToBottom="scrollToBottom"/>
      </div>
      <ion-card v-else-if="hasLoadedOnce">
        <ion-card-content>
          No message found.
        </ion-card-content>
      </ion-card>
    </ion-content>
    <MessageInput ref="messageInput" @scrollToBottom="scrollToBottom" @sendMessage="sendMessage"/>
  </ion-page>
</template>

<script>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLoading,
  IonToast,
  IonCard,
  IonCardContent,
} from '@ionic/vue'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { PushNotifications } from '@capacitor/push-notifications'
import { defineComponent, nextTick } from 'vue'
import { mapGetters } from 'vuex'
import { USER_CHECK } from '../store/actions/user'
import dayjs from 'dayjs'
import axios from 'axios'
import Message from '../components/Message'
import MessageInput from '../components/MessageInput'
import { dismissElement } from '../utils/overlay'

export default defineComponent({
  name: 'Messages',
  emits: ['markMessagesRead'],
  data () {
    return {
      hasLoadedOnce: false,
      loading: false,
      socket: null,
      messageList: [],
      error: false,
      errorMessage: 'There was an error sending your message. Please try again later.',
      chatName: 'Messages',
      listeners: [],
    }
  },
  computed: mapGetters([
    'isAuthenticated',
    'userProfile',
    'sessionToken',
  ]),
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading,
    IonToast,
    IonCard,
    IonCardContent,
    Message,
    MessageInput,
  },
  methods: {
    setError (errorMessage) {
      if (errorMessage) {
        this.errorMessage = errorMessage
      }
      this.error = true
      this.loading = false
    },
    showError (errorMessage) {
      let toast = document.getElementsByTagName('ion-toast')[0]
      if (this.error && toast) {
        toast.dismiss().then(() => {
          this.setError(errorMessage)
        })
      } else {
        this.setError(errorMessage)
      }
    },
    dismissError () {
      this.error = false
      this.errorMessage = 'There was an error sending your message. Please try again later.'
    },
    getReceiverID () {
      let receiver = (((this.userProfile ||{}).role_data || {}).medical_assistant || {}).user
      if (!receiver) {
        receiver = (this.messageList[0] || {}).author
      }
      return receiver
    },
    sendMessage (text) {
      let sent = dayjs().toISOString()
      let receiver = this.getReceiverID()
      let data = {
        text: text,
        sent: sent,
        sent_from: this.userProfile.id,
        sent_to: receiver
      }
      if (this.isSocketReady()) {
        this.socket.send(JSON.stringify(data))
      } else {
        axios.post('/patient_messages/', data).then((response) => {
          this.messageList.push({
            id: response.data.id,
            type: 'text',
            author: 'me',
            data: {
              text: text,
              meta: sent,
              secure: true,
            },
          })
          nextTick(this.scrollToBottom)
        }).catch(({response}) => {
          this.showError()
          let responseMessage = `${response.status} ${response.statusText} - ${response.data}`
          throw new Error(`PatientApp - sendMessage: ${JSON.stringify(data)} -> ${responseMessage}`)
        })
      }
    },
    setChatName () {
      if (this.chatName !== 'Messages') {return}
      let receiverName = (((this.userProfile ||{}).role_data || {}).medical_assistant || {}).display_name
      if (!receiverName) {
        receiverName = (this.messageList[0] || {}).authorName
      }
      if (receiverName) {
        let titleElement = document.querySelector("ion-title").shadowRoot.querySelector(".toolbar-title")
        if (titleElement) {
          titleElement.style['overflow'] = 'visible'
        }
        this.chatName = receiverName
      }
    },
    fetchMessages () {
      this.loading = true
      this.$refs.messageInput.blurInput()
      axios({
        method: 'get',
        url: '/patient_messages/',
        params: {patient: this.userProfile.id},
      }).then((response) => {
        this.messageList = response.data.map((message) => {
          let author = this.userProfile.id === message.sent_from ? 'me' : message.sent_from
          let type = message.sent_from ? 'text' : 'system'
          return {
            id: message.id,
            type: type,
            author: author,
            authorID: message.sent_from_id,
            authorName: message.sent_from_name,
            data: {
              text: message.text,
              meta: message.sent,
              secure: message.secure,
              media: message.media,
            },
          }
        })
        this.scrollToBottom()
        this.loading = false
        if (!this.hasLoadedOnce) {
          this.setChatName()
          this.hasLoadedOnce = true
        }
      })
    },
    authenticateSocket () {
      // WS: Connection established. Authenticating...
      let data = {
        token: this.sessionToken,
        receiver: this.getReceiverID(),
      }
      this.socket.send(JSON.stringify(data))
    },
    onMessageReceived (data) {
      let response = JSON.parse(data.data)
      if (response.status && response.status === 'success')  {
        // WS: Authenticated successfully.
      } else if (response.error) {
        throw new Error(`WS: Authentication failed. ${response.error}`)
      } else if (response.content) {
        let message = response.content
        let author = this.userProfile.id === message.sent_from ? 'me' : message.sent_from
        let type = message.sent_from ? 'text' : 'system'
        this.messageList.push({
          id: message.id,
          type: type,
          author: author,
          authorID: message.sent_from_id,
          authorName: message.sent_from_name,
          data: {
            text: message.text,
            meta: message.sent,
            secure: message.secure,
            media: message.media,
          },
        })
        nextTick(this.scrollToBottom)
      } else {
        throw new Error(`WS: Unsupported message received. ${response}`)
      }
    },
    onSocketClosed () {
      // WS: Socket closed.
      this.socket = null
    },
    onSocketError () {
      this.socket = null
      throw new Error('WS: Connection failed. Falling back to HTTP...')
    },
    isSocketReady () {
      return this.socket && this.socket.readyState === this.socket.OPEN
    },
    scrollToBottom () {
      document.getElementById('ion-content').getScrollElement().then((element) => {
        element.scrollTop = element.scrollHeight
      })
    },
    addPushNotificationListeners () {
      if (!Capacitor.isPluginAvailable('PushNotifications')) {return}
      PushNotifications.addListener('pushNotificationReceived', ({data}) => {
        if (data.topic !== 'patient_message') {return}
        // If the app is on the foreground when notification is received and WS is unavailable, refresh messages
        if (!this.isSocketReady()) {
          this.fetchMessages()
        }
        this.$parent.$emit('markMessagesRead')
      }).then(listener => {
        this.listeners.push(listener)
      })
    },
    addAppListeners () {
      if (!Capacitor.isPluginAvailable('App')) {return}
      // If the app comes to foreground from background and WS is unavailable, refresh messages
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive && !this.isSocketReady()) {
          this.fetchMessages()
        }
      }).then(listener => {
        this.listeners.push(listener)
      })
    },
    initializeSocket () {
      // WS: Opening a WebSocket connection...
      let [apiProtocol, apiHost] = process.env.VUE_APP_API_HOST.split('//')
      let nativeProduction = Capacitor.isNative && process.env.VUE_APP_NODE_ENV === 'production'
      let socketProtocol = (apiProtocol === 'https:' || nativeProduction) ? 'wss:' : 'ws:'
      this.socket = new WebSocket(`${socketProtocol}//${apiHost}/ws/patient_messages/`)
      this.socket.onopen = this.authenticateSocket
      this.socket.onmessage = this.onMessageReceived
      this.socket.onerror = this.onSocketError
      this.socket.onclose = this.onSocketClosed
    },
    handleBackNavigation () {
      let previousPath = this.$router.options.history.state.back
      if (!previousPath || previousPath === '/login') {
        this.$router.push('/profile')
      } else {
        this.$router.back()
      }
    },
  },
  ionViewWillEnter () {
    dismissElement('ion-loading')
    this.$store.dispatch(USER_CHECK).then(() => {
      if (!this.isAuthenticated) {return}
      this.initializeSocket()
      this.fetchMessages()
      this.addPushNotificationListeners()
      this.addAppListeners()
      this.$parent.$emit('markMessagesRead')
    })
  },
  ionViewWillLeave () {
    if (this.socket) {
      this.socket.close()
    }
    this.listeners.forEach(listener => {
      listener.remove()
    })
    this.listeners = []
  },
})
</script>

<style scoped>
.message-container {
  padding: 5px 25px 25px 25px;
}
</style>
