<template>
  <div v-if="message.type === 'system'" class="message-system">
    <p class="message-system-date">
      {{ formatDate() }}
    </p>
    {{ message.data.text }}
  </div>
  <div v-else class="message" :class="getClass()">
    <div class="message-block">
      <p>
        {{ formatDate() }}
      </p>
      <div class="message-text">
        <div v-if="message.author !== 'me'" class="message-sender">
          {{ message.authorName }}
        </div>
        <div v-if="message.data.media" class="message-media-container">
          <ion-spinner v-if="imageLoading" class="message-media-spinner"/>
          <img :src="message.data.media" @load="imageLoaded()" class="message-media"/>
        </div>
        {{ message.data.text }}
        <div v-if="!message.data.secure" class="message-unsecured">
          <ion-icon :ios="alertCircleOutline" :md="alertCircleOutline"/>
          Unsecured
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonIcon, IonSpinner } from '@ionic/vue'
import { alertCircleOutline } from 'ionicons/icons'
import { mapGetters } from 'vuex'
import { USER_CHECK } from '../store/actions/user'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/en-gb'

dayjs.extend(relativeTime)

export default defineComponent({
  name: 'Message',
  setup () {return {alertCircleOutline}},
  emits: ['scrollToBottom',],
  components: {
    IonIcon,
    IonSpinner,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      imageLoading: true,
    }
  },
  methods: {
    getReceiverID () {
      let receiver = (((this.userProfile || {}).role_data || {}).medical_assistant || {}).user
      if (!receiver && this.message.author !== 'me') {
        receiver = this.message.author
      }
      return receiver
    },
    getClass () {
      if (this.message.author === 'me') {
        return 'sent'
      }
      let className = 'received '
      let receiver = this.getReceiverID()
      if (this.message.author === receiver) {
        className += 'assistant'
      } else if (this.message.authorID) {
        className += 'previousAssistant'
      } else {
        className += 'staff'
      }
      return className
    },
    formatDate () {
      return dayjs(this.message.data.meta).fromNow()
    },
    imageLoaded () {
      this.imageLoading = false
      this.$emit('scrollToBottom')
    },
  },
  computed: mapGetters([
    'userProfile',
  ]),
  created () {
    this.$store.dispatch(USER_CHECK)
  },
})
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  align-items: flex-start;
  .message-block {
    position: relative;
    word-break: break-word;
    p {
      font-size: 0.6em;
      color: var(--ion-color-dark-tint);
      margin-block-end: 5px;
      opacity: 0.7;
    }
    .message-text {
      position: relative;
      display: block;
      padding: 5px 10px;
      line-height: 1.4;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }
  }
  &.sent {
    flex-direction: row-reverse;
    .message-block {
      margin-left: 50px;
      p {
        text-align: right;
      }
      .message-text {
        color: var(--ion-color-primary-contrast);
        background-color: var(--ion-color-primary);
        border-radius: 15px 0px 15px 15px;
        &::before {
          right: -4px;
          border-top: 10px solid var(--ion-color-primary);
        }
        .message-media-spinner {
          --color: var(--ion-color-primary-contrast);
        }
      }
    }
    + .sent {
      margin-top: 5px;
      ::before, p {
        display: none;
      }
      .message-text {
        border-radius: 15px;
      }
    }
  }
  &.received {
    .message-block {
      position: relative;
      margin-right: 50px;
      .message-text {
        border-radius: 0px 15px 15px 15px;
        color: var(--ion-color-light-contrast);
        background-color: var(--ion-color-light);
        &::before {
          left: -4px;
          border-top: 10px solid var(--ion-color-light);
        }
        .message-sender {
          font-weight: 500;
          font-size: 0.85em;
          padding-bottom: 5px;
        }
        .message-media-spinner {
          --color: var(--ion-color-light-contrast);
        }
      }
    }
  }
  &.staff {
    .message-sender {
      color: var(--ion-color-warning-shade);
    }
    + .staff {
      margin-top: 5px;
      ::before, p, .message-sender {
        display: none;
      }
      .message-text {
        border-radius: 15px;
      }
    }
  }
  &.assistant {
    .message-sender {
      color: var(--ion-color-tertiary-shade);
    }
    + .assistant {
      margin-top: 5px;
      ::before, p, .message-sender {
        display: none;
      }
      .message-text {
        border-radius: 15px;
      }
    }
  }
  &.previousAssistant {
    .message-sender {
      color: var(--ion-color-primary);
    }
    + .previousAssistant {
      margin-top: 5px;
      ::before, p, .message-sender {
        display: none;
      }
      .message-text {
        border-radius: 15px;
      }
    }
  }
}
.message-system {
  + .message-system {
    .message-system-date {
      display: none;
    }
  }
}
</style>

<style scoped>
.message-unsecured {
  opacity: 0.7;
  word-wrap: normal;
  font-size: smaller;
  text-align: center;
  margin-top: 0.2rem;
}
.message-media-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}
.message-media {
  border-radius: 15px;
  margin-top: 5px;
  max-height: 300px;
}
.message-media-spinner {
  position: absolute;
}
.message-system {
  margin: 20px 10%;
  white-space: pre-line;
  opacity: .55;
  font-size: 0.9em;
}
.message-system-date {
  opacity: .5;
  text-align: right;
  margin-block-end: 0;
  font-size: 0.67em;
}
</style>
